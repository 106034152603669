<template>
    <div class="Awarp">
        <dashCard class="lvscBox3" :operateWidth="operateWidth" :isLoading="isLoading" :gridOption="gridOption" @changeScreen="changeScreen">
            <template slot="title">{{commonLangHandler('lvscBox3_deviceOnStatus','设备在线状态', getZEdata)}}</template>
            <template slot="aside"></template>
            <div class="card-content1">
                <chartBoard ref="chart1" :option="chartOption1"/>
            </div>
        </dashCard>
        <a-modal v-if="!isModal" class="Amodal" v-model="option.visible" :closable="false" :footer="null" :keyboard="false" width="100%" :dialog-style="{ top: '0px' }">
            <div style="height:calc(100vh)">
                <lvscBox3  :option="option" :isModal="true" :orginSearchObj="searchObj"/>
            </div>
        </a-modal>
    </div>
    </template>
    <script>
    export default {
        name: 'lvscBox3',
        components: {
            dashCard: () => import('@/component/dashCard.vue'),
            chartBoard: () => import('@/component/chartBoard.vue'),
        },
        props: {
            gridOption: {
                type: Object,
                default: function() {
                    return { w: 6, h: 6 }
                }
            },
            option: {
                type: Object,
                default: function() {
                    return {
                        visible: false
                    }
                }
            },
            isModal: {
                type: Boolean,
                default: function() {
                    return false
                }
            },
            orginSearchObj: {
                type: Object,
                default: function() {
                    return {}
                } 
            }
        },
        watch: {
            gridOption: {
                handler: function(newVal,oldVal) {
                    this.$nextTick(_=>{
                        this.getOperateWidth()
                        var dom = this.$refs['chart1']
                        if(!!dom) {
                            dom.handleWindowResize()
                        }
                    })
                },
                deep: true,
                immediate: true
            },
            "option.visible": {//全屏化时执行
                handler: function(newVal,oldVal) {
                    if(newVal) {
                        if(this.isModal) {
                            this.searchObj = this.orginSearchObj
                            this.getData()
                        }
                    }
                },
                deep: true,
                immediate: true
            },
            "currentSelectDeptInfo.CODE": {
                handler: function(newVal,oldVal) {
                    if(oldVal) {
                        this.getData()
                    }
                },
                deep: true,
                immediate: true
            }
        },
        data() {
            return {
                operateWidth: 64, //操作列宽度
                isLoading: false,//数据加载中

                searchObj: {},
                chartOption1: {},
                detailInfo: {
                    chart1: {},
                    list: []
                },
            }
        },
        computed: {
            // 当前登录用户信息
            currentuserinfo() {
                return this.$store.state.currentuserinfo
            },
            // 当前Dept信息
            currentSelectDeptInfo() {
                return this.$store.state.currentSelectDeptInfo
            }
        },
        methods: {
            // 全屏切换
            changeScreen() {
                this.option.visible = !this.isModal
                if(!this.option.visible) {
                    this.getData()
                }
            },
            // 右侧操作列宽度
            getOperateWidth () {
                if(!this.$el.querySelector) {
                    return
                }
                var dom = this.$el.querySelector(".card-head-operate")
                if(!!dom) {
                    var width = dom.clientWidth
                    this.operateWidth = width > 0 ? width + 12 : width
                }
            },
            // 数字转为千位分隔符表示
            toThousandsSeparator: function(value) {
                if(!value) return 0
                // 获取整数部分
                const intPart = Math.trunc(value)
                // 整数部分处理，增加,
                const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
                // 预定义小数部分
                let floatPart = ''
                // 将数值截取为小数部分和整数部分
                const valueArray = value.toString().split('.')
                if (valueArray.length === 2) { // 有小数部分
                    floatPart = valueArray[1].toString() // 取得小数部分
                    return intPartFormat + '.' + floatPart
                }
                return intPartFormat + floatPart
            },

            handleChange(value) {
                this.getData()
            },
            //每月报警预警数
            getData() {
                const url =  Config.dev_url + "/api-apps/stationOverview/getOnlineEquipmentStatus";
                var data = {
                    code: this.currentSelectDeptInfo.CODE
                }
                this.isLoading = true
                this.$axios.post(url,data).then(res=>{
                    if(res.data.code==0){
                        var {online,total,EquipmentInfo} = res.data.data
                        this.detailInfo = {}
                        var chart1 = {
                            min: 0,
                            max: 0,
                            value: 0
                        }
                        chart1.max = total
                        chart1.value = online
                        this.detailInfo.list = EquipmentInfo
                        this.detailInfo.chart1 = chart1
                        this.initEchart()
                    }else{
                        // this.$message.warning(res.data.msg)
                    }
                }).catch((wrong)=>{ }).finally(()=>{
                    this.isLoading = false
                })
            },
            getData0() {
                this.detailInfo = {}
                var chart1 = {
                    min: 0,
                    max: 27,
                    value: 22
                }
                this.detailInfo.chart1 = chart1
                this.initEchart()
            },
            initEchart() {
                var {min,max,value} = this.detailInfo.chart1

                var option = {
                    animation:false,
                    layoutAnimation:false,
                    color: ["#DBDBDB","#2A35FF"],
                    tooltip: {
                        trigger: 'item'
                    },
                    series: [
                        // 背景
                        {
                            animation: false,
                            name: '设备在线状态',
                            radius:'100%',
                            type: 'gauge',
                            center: ['50%', '50%'],
                            splitNumber: 12,
                            // 展示当前进度
                            progress: {
                                show: true,
                                width: 5,
                                itemStyle: {
                                    color: '#DBDBDB',
                                    fontFamily: "ABBvoice_WCNSG_Rg"
                                },
                            },
                            // 仪表盘指针
                            pointer: {
                                show: false,
                            },
                            // 仪表盘轴线相关配置
                            axisLine: {
                                show: false,
                            },
                            // 刻度样式
                            axisTick: {
                                show: false,
                            },
                            // 分隔线样式
                            splitLine: {
                                show: false,
                            },
                            // 刻度标签
                            axisLabel: {
                                show: false,
                            },
                            // 表盘中指针的固定点
                            anchor: {
                                show: false,
                            },
                            detail: {
                                show: false,
                            },
                            data: [
                                {
                                    value: 100,
                                    fontFamily: "ABBvoice_WCNSG_Rg"
                                },
                            ],
                        },
                        // 内容
                        {
                            name: '',
                            type: 'gauge',
                            radius:'100%',
                            center: ['50%', '50%'],
                            min: min,
                            max: max,
                            zlevel: 10,
                            splitNumber: 12,
                            // 展示当前进度
                            progress: {
                                show: true,
                                width: 5,
                                itemStyle: {
                                    color: '#2A35FF',
                                    fontFamily: "ABBvoice_WCNSG_Rg"
                                },
                            },
                            // 仪表盘指针
                            pointer: {
                                show: false,
                            },
                            // 仪表盘轴线相关配置
                            axisLine: {
                                show: false,
                            },
                            // 刻度样式
                            axisTick: {
                                show: false,
                            },
                            // 分隔线样式
                            splitLine: {
                                show: false,
                            },
                            splitNumber: 1,
                            // 刻度标签
                            axisLabel: {
                                show: true,
                                padding: [0, 10, 0, 10],
                                distance: -30,
                            },
                            // 表盘中指针的固定点
                            anchor: {
                                show: false,
                            },
                            detail: {
                                borderRadius: 8,
                                offsetCenter: ['0%', '-15%'],
                                fontSize: 30,
                                fontWeight: 'bolder',
                                formatter: '{value}',
                                color: 'rgba(0,0,0,.65)',
                                fontFamily: "ABBvoice_WCNSG_Rg"
                            },
                            data: [
                                {
                                    value: value,
                                    name: '台'
                                },
                            ],
                        },
                    ],
                };




                this.updateChart('chart1', 'chartOption1', option)
            },
            updateChart: function (refName, optionName, option = {}) {
                /* 渲染echart图 */
                if (!optionName) return
                this[optionName] = option
                setTimeout(() => {
                    this.$refs[refName].updateChartView()
                }, 500)
            },

        },

        mounted() {
            this.getOperateWidth()
            if(!this.option.visible) {
                this.getData()
            }
        }
    }
    </script>

<style lang="less" scoped>
.lvscBox3 {
    .card-content1 {
        flex: 1;
        height: 100%;
        div{
            width: 100%;
            height: 100%;
        }
    }
}
</style>
